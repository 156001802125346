@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ced4da;
}
p {
  font-size: 1.15rem;
  line-height: 1.6;
}

.full-height {
  min-height: calc(100vh - 152px) !important;
}

.navbar-custom {
  background-color: rgb(12, 12, 12) !important;
  padding: 0 !important;
}
.background {
  background-image: url("./images/background.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  filter: grayscale(35%);
}

.btn-primary,
.login_button {
  background-color: #4b0082 !important;
  border-color: #4b0082 !important;
  color: #ced4da;
}
.login_button:hover {
  border: 1px solid #ee82ee !important;
  color: white !important;
  background-color: transparent !important;
}

.secundairy_button {
  padding: 10px 20px !important;
  font-weight: 400 !important;
  letter-spacing: 1px;
  color: white !important;
  background-color: #4b0082 !important;
  border-radius: 25px !important;
  z-index: 1;
}
.secundairy_button:hover {
  border: 1px solid #ee82ee !important;
  color: white !important;
  background-color: transparent !important;
}

.form_button {
  color: white !important;
  background-color: #ff1493 !important;
  z-index: 1;
}
.form_button:hover {
  border: 1px solid #ee82ee !important;
  color: white !important;
  background-color: transparent !important;
}

.pink {
  color: #4b0082 !important;
}
.nav-menu {
  display: flex;
  align-items: center;
}
.activeNavItem {
  color: #ff1493 !important;
  font-size: 1.3rem !important;
}
.nav-link {
  display: flex !important;
  align-items: center !important;
  text-decoration: none !important;
  padding: 0 1rem !important;
  justify-content: center !important;
  height: 80px !important;
  letter-spacing: 1px !important;
  cursor: pointer !important;
  font-size: 1.15rem !important;
}
.nav-link:hover {
  color: #ee82ee !important;
}
.navbar-brand {
  padding: 0 !important;
}
.footer {
  background-color: rgb(12, 12, 12) !important;
  color: #ced4da;
}
.error_text {
  color: #ffa500 !important;
  margin-top: 6px !important;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon {
  font-size: 2rem;
  margin: 1.25rem;
  color: #ced4da !important;
}
.icon:hover {
  color: #4b0082 !important;
}
.smallfs {
  font-size: 14px !important;
}

/* OK */
.carousel-image {
  width: 100%;
  height: 100%;
  min-height: 60vh;
  max-height: 80vh;
  object-fit: cover;
}

.nav-logo {
  height: 55px !important;
  width: auto !important;
}
.icon_green {
  color: green !important;
}
.icon_green:hover {
  color: darkgreen !important;
}
.grab {
  cursor: grab;
}
.icon_red {
  color: red !important;
}
.icon_red:hover {
  color: darkred !important;
}
.icon_change {
  color: #ff1493 !important;
}
.icon_change:hover {
  color: #ee82ee !important;
}
.section_title {
  margin-bottom: 18px !important;
  letter-spacing: 1.25px;
  color: #ff1493 !important;
  font-weight: 300 !important;
}
.section_content_title {
  color: #ee82ee !important;
}
.concerts_date {
  color: #ee82ee !important;
}

.carousel_logo {
  max-height: 60vh;
  height: auto;
}

.horizontal_card {
  background-color: transparent !important;
  border-color: #4b0082 !important;
  overflow: hidden;
}
.horizontal_card .card-body {
  position: relative;
  overflow: hidden;
}

.horizontal_card .titleImg {
  max-width: 40%;
  height: auto;
}

.horizontal_card .float-left {
  float: left;
}

.horizontal_card .float-right {
  float: right;
}

.left_img_mg {
  margin: 0px 10px 0px 0px;
}

.right_img_mg {
  margin: 0px 0px 0px 10px;
}

.card_title {
  letter-spacing: 1px; /* Add some letter spacing for a modern look */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  color: #ee82ee !important;
  padding-bottom: 24px;
  padding-top: 8px;
}

.custom_card_title {
  letter-spacing: 1px; /* Add some letter spacing for a modern look */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  color: #ced4da !important;
  padding-bottom: 24px;
  padding-top: 8px;
}

@media only screen and (max-device-width: 767px) {
  .horizontal_card .titleImg {
    margin: 0px 0px 12px 0px !important;
    max-width: 100% !important;
    height: auto;
  }
  .full-height {
    min-height: calc(100vh - 260px) !important;
  }
}

@media only screen and (max-device-width: 992px) {
  .horizontal_card .titleImg {
    max-width: 55%;
    height: auto;
  }
}

.media_card {
  background-color: transparent !important;
  border-color: #4b0082 !important;
  overflow: hidden;
}
.login_card {
  background-color: transparent !important;
  overflow: hidden;
  width: 355px;
}
.concerts_card {
  background-color: transparent !important;
  border-color: #888888 !important;
  overflow: hidden;
}

.concerts_name,
.concerts_icon {
  color: white;
}
.hidden_button {
  visibility: hidden !important;
}
.icon_margin {
  margin-right: 6px !important;
}
.no_btn_style {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
